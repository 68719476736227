<template>
    <div class="editResume">
        <div class="personal package" id="anchor-1">
            <Title1 :titles="'编辑个人信息'" wh="687"></Title1>
            <el-form
                    ref="personalForm"
                    :model="personalForm"
                    :rules="personalRules"
                    label-position="top"
                    :disabled="showEdit.box != 1"
            >
                <el-row>
                    <el-form-item label="上传头像" class="uploadAvatar" prop="headImgUrl">
                        <el-upload
                                class="avatar-uploader"
                                action="http://danlingzp.com/prod-api/system/oss/upload"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                        >
                            <img
                                    v-if="personalForm.headImgUrl"
                                    :src="personalForm.headImgUrl"
                                    class="avatar"
                            />
                            <img
                                    class="defaultPicture"
                                    v-else
                                    src="@/assets/img/Index_JobSeeker/icon25.png"
                                    alt=""
                            />
                        </el-upload>
                    </el-form-item>
                </el-row>
                <el-row class="fs">
                    <el-form-item label="姓名" prop="name">
                        <el-input
                                v-model="personalForm.name"
                                placeholder="请输入您的姓名"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="性别" class="middle radioStyle" prop="gender">
                        <el-radio-group v-model="personalForm.gender">
                            <el-radio
                                    v-for="(item, index) in ['女', '男']"
                                    :key="index"
                                    :label="index.toString()"
                                    border
                            >{{ item }}
                            </el-radio
                            >
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="出生日期" prop="birthday">
                        <el-date-picker
                                v-model="personalForm.birthday"
                                value-format="yyyy-MM-dd"
                                type="date"
                                @change="changeAge"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-row>
                <el-row class="fs">
                    <el-form-item label="最高学历" prop="educationMax">
                        <el-select v-model="personalForm.educationMax" placeholder="请选择">
                            <el-option
                                    v-for="item in educationList"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="phone" class="middle">
                        <el-input
                                v-model.number="personalForm.phone"
                                placeholder="请输入手机号码"
                                maxlength="11"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="工作年限" prop="jobExperience">
                        <el-input
                                v-model.number="personalForm.jobExperience"
                                placeholder="请输入工作年限"
                        ></el-input>
                    </el-form-item>
                </el-row>
                <el-row class="fs last">
                    <el-form-item label="邮箱" prop="email">
                        <el-input
                                v-model="personalForm.email"
                                placeholder="请输入您的联系邮箱"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="  " class="middle"></el-form-item>
                    <el-form-item label="  " class="btnList">
                        <el-button class="no" v-if="showEdit.box == 1" @click="$emit('update:showEdit', editObject)"
                        >取消
                        </el-button
                        >
                        <el-button class="ok" v-if="showEdit.box == 1" @click="submitForm('personalForm')"
                        >确认
                        </el-button
                        >
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
        <div class="advantage package" id="anchor-2">
            <Title1 :titles="'编辑个人优势'" wh="687"></Title1>
            <el-form
                    ref="advantageForm"
                    :model="advantageForm"
                    :rules="advantageRules"
                    label-position="top"
                    :disabled="showEdit.box != 2"
            >
                <el-form-item prop="personalAdvantage">
                    <el-input
                            type="textarea"
                            v-model="advantageForm.personalAdvantage"
                            placeholder="多行输入"
                    ></el-input>
                </el-form-item>
                <el-row class="last lastBtn">
                    <el-form-item class="btnList">
                        <el-button class="no" v-if="showEdit.box == 2" @click="$emit('update:showEdit', editObject)"
                        >取消
                        </el-button
                        >
                        <el-button class="ok" v-if="showEdit.box == 2" @click="submitForm('advantageForm')"
                        >确认
                        </el-button
                        >
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
        <div class="desire package" id="anchor-3">
            <Title1 :titles="'编辑求职愿望'" wh="687"></Title1>
            <el-form
                    ref="desireForm"
                    label-position="top"
                    :model="desireForm"
                    :rules="desireRules"
                    :disabled="showEdit.box != 3"
            >
                <el-row class="fs">
                    <el-form-item label="职业类型" class="radioStyle" prop="jobType">
                        <el-radio-group v-model="desireForm.jobType">
                            <el-radio
                                    v-for="(item, index) in industryList"
                                    :key="index"
                                    :label="item.dictCode"
                                    border
                            >{{ item.dictLabel }}
                            </el-radio
                            >
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="期望城市" prop="city" class="middle">
                        <el-cascader
                                ref="cascaderAddr"
                                clearable
                                :props="props"
                                @expand-change="expandChange"
                                :options="test_options"
                                v-model="desireForm.city"
                                placeholder=""
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label="职业行业" prop="expectedPositionType">
                        <el-select
                                v-model="desireForm.expectedPositionType"
                                placeholder="请选择"
                                @change="(v) => {selectChange2(v,this.industryDictionary, 'expectedPositionTypeName',this.desireForm),getJobListPage(v,1)}"
                                clearable=""
                                filterable=""
                        >
                            <el-option
                                    v-for="item in industryDictionary"
                                    :key="item.dictCode"
                                    :label="item.dictValue"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-row>
                <el-row class="fs">
                    <el-form-item
                            label="期望岗位"
                            prop="expectedPositionCode"
                            class="industry"
                    >
                        <el-select
                                v-model="desireForm.expectedPositionCode"
                                placeholder="请选择"
                                @change="(v) => selectChange2(v,this.postDictionary, 'expectedPositionName',this.desireForm)"
                                clearable=""
                                filterable=""
                        >
                            <el-option
                                    v-for="item in postDictionary"
                                    :key="item.dictCode"
                                    :label="item.dictValue"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            label="期望薪资"
                            prop="expectedSalaryMax"
                            class="middle"
                    >
                        <el-select
                                v-model="desireForm.expectedSalaryMax"
                                clearable
                                filterable=""
                                placeholder="请选择"
                        >
                            <el-option
                                    v-for="item in salaryList"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="  " class="btnList">
                        <el-button class="no" v-if="showEdit.box == 3" @click="$emit('update:showEdit', editObject)"
                        >取消
                        </el-button
                        >
                        <el-button class="ok" v-if="showEdit.box == 3" @click="submitForm('desireForm')"
                        >确认
                        </el-button
                        >
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
        <div class="work package" id="anchor-4">
            <Title1 :titles="'编辑工作经历'" wh="687"></Title1>
            <el-form
                    ref="workForm"
                    :model="workForm"
                    :rules="workRules"
                    label-position="top"
                    :disabled="showEdit.box != 4"
            >
                <el-row class="fs">
                    <el-form-item label="公司名称" prop="corporateName">
                        <el-input
                                v-model="workForm.corporateName"
                                placeholder="请输入公司名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="在职时间" prop="jobStartTime" class="middle goodsManage sectionTime">
                        <el-date-picker v-model="workForm.jobStartTime" type="month" placeholder="请选择"
                                        value-format="yyyy-MM"
                                        :clearable="false"
                                        :picker-options="{disabledDate:data=>startDatePicker(data,workForm.jobEndTime)}">
                        </el-date-picker>
                        <span class="" style="margin: 0 5px 0px 5px"> -</span>
                        <el-date-picker
                                :class="workForm.jobTime=='至今'&&!workForm.jobEndTime?'time-picker':''"
                                v-model="workForm.jobEndTime" prefix-icon='el-icon-date' type="month"
                                :placeholder="workForm.jobTime=='至今'&&!workForm.jobEndTime?'':'请选择'"
                                @change="changeTime('jobEndTime','jobTime','workForm')"
                                :clearable="false"
                                value-format="yyyy-MM" :picker-options="{
                    disabledDate: data =>endDatePicker(data, workForm.jobStartTime),
                    shortcuts: [
                       {
                            text: '至今',
                            onClick(picker) {
                            workForm.jobTime='至今'
                                 picker.$emit('pick', '');
                                 workForm.jobEndTime=''
                            }
                        }]
                }">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="所属行业" prop="companyIndustry">
                        <el-select
                                v-model="workForm.companyIndustry"
                                placeholder="请选择"
                                clearable=""
                                filterable=""
                                @change="(v) => {selectChange2(v,this.industryDictionary, 'companyIndustryName',this.workForm),getJobListPage(v,2)}"
                        >
                            <el-option
                                    v-for="item in industryDictionary"
                                    :key="item.dictCode"
                                    :label="item.dictValue"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item
                            label="担任岗位"
                            prop="positionCode"
                            class="industry"
                    >
                        <el-select
                                v-model="workForm.positionCode"
                                placeholder="请选择"
                                clearable=""
                                filterable=""
                                @change="(v) => selectChange2(v,this.postDictionary, 'positionName',this.workForm)"
                        >
                            <el-option
                                    v-for="item in postDictionary"
                                    :key="item.dictCode"
                                    :label="item.dictValue"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="工作内容" prop="jobContent">
                        <el-input
                                type="textarea"
                                v-model="workForm.jobContent"
                                placeholder="多行输入"
                        ></el-input>
                    </el-form-item>
                </el-row>
                <el-row class="last lastBtn">
                    <el-form-item class="btnList">
                        <el-button class="no" v-if="showEdit.box == 4" @click="$emit('update:showEdit', editObject)"
                        >取消
                        </el-button
                        >
                        <el-button class="ok" v-if="showEdit.box == 4" @click="submitForm('workForm')"
                        >确认
                        </el-button
                        >
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
        <div class="project package" id="anchor-5">
            <Title1 :titles="'编辑项目经历'" wh="687"></Title1>
            <el-form
                    ref="projectForm"
                    label-position="top"
                    :model="projectForm"
                    :rules="projectRules"
                    :disabled="showEdit.box != 5"
            >
                <el-row class="fs">
                    <el-form-item label="项目名称" prop="projectName">
                        <el-input
                                v-model="projectForm.projectName"
                                placeholder="请输入项目名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="项目时间" prop="startTime" class="middle goodsManage sectionTime">
                        <el-date-picker v-model="projectForm.startTime" type="month" placeholder="请选择"
                                        value-format="yyyy-MM"
                                        :clearable="false"
                                        :picker-options="{disabledDate:data=>startDatePicker(data,projectForm.endTime)}">
                        </el-date-picker>
                        <span class="" style="margin: 0 5px 0px 5px"> -</span>
                        <el-date-picker
                                :class="projectForm.projectTime=='至今'&&!projectForm.endTime?'time-picker':''"
                                v-model="projectForm.endTime" prefix-icon='el-icon-date' type="month"
                                :placeholder="projectForm.projectTime=='至今'&&!projectForm.endTime?'':'请选择'"
                                @change="changeTime('endTime','projectTime','projectForm')"
                                :clearable="false"
                                value-format="yyyy-MM" :picker-options="{
                    disabledDate: data =>endDatePicker(data, projectForm.startTime),
                    shortcuts: [
                       {
                            text: '至今',
                            onClick(picker) {
                            projectForm.projectTime='至今'
                                 picker.$emit('pick', '');
                                 projectForm.endTime=''
                            }
                        }]
                }">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="担任角色" prop="playRole">
                        <el-input
                                v-model="projectForm.playRole"
                                placeholder="请输入担任角色"
                        ></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="项目介绍" prop="projectContent">
                        <el-input
                                type="textarea"
                                v-model="projectForm.projectContent"
                                placeholder="多行输入"
                        ></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="工作职责" prop="jobDuty">
                        <el-input
                                type="textarea"
                                v-model="projectForm.jobDuty"
                                placeholder="多行输入"
                        ></el-input>
                    </el-form-item>
                </el-row>
                <el-row class="last lastBtn">
                    <el-form-item class="btnList">
                        <el-button class="no" v-if="showEdit.box == 5" @click="$emit('update:showEdit', editObject)"
                        >取消
                        </el-button
                        >
                        <el-button class="ok" v-if="showEdit.box == 5" @click="submitForm('projectForm')"
                        >确认
                        </el-button
                        >
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
        <div class="education" id="anchor-6">
            <Title1 :titles="'编辑教育经历'" wh="687"></Title1>
            <el-form
                    ref="educationForm"
                    label-position="top"
                    :disabled="showEdit.box != 6"
                    :model="educationForm"
                    :rules="educationRules"
            >
                <el-row class="fs">
                    <el-form-item label="学校名称" prop="schoolName">
                        <el-input
                                v-model="educationForm.schoolName"
                                placeholder="请输入学校名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                            label="学制类型"
                            prop="educationSystemType"
                            class="middle"
                    >
                        <el-select
                                v-model="educationForm.educationSystemType"
                                placeholder="请选择"
                        >
                            <el-option
                                    v-for="item in educationSystemList"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学历" prop="education">
                        <el-select v-model="educationForm.education" placeholder="请选择">
                            <el-option
                                    v-for="item in educationList"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-row>
                <el-row class="fs">
                    <el-form-item label="专业" prop="professionalName">
                        <el-input
                                v-model="educationForm.professionalName"
                                placeholder="请输入专业"
                        ></el-input>
                    </el-form-item>
<!--                    <el-form-item label="学习时间" prop="studyTime" class="middle">-->
<!--                        <el-date-picker-->
<!--                                v-model="educationForm.studyTime"-->
<!--                                type="daterange"-->
<!--                                range-separator="-"-->
<!--                                start-placeholder="开始日期"-->
<!--                                end-placeholder="结束日期"-->
<!--                                value-format="yyyy-MM-dd"-->
<!--                        >-->
<!--                        </el-date-picker>-->
<!--                    </el-form-item>-->
                    <el-form-item label="学习时间" prop="schoolStartTime" class="middle goodsManage sectionTime">
                        <el-date-picker v-model="educationForm.schoolStartTime" type="month" placeholder="请选择"
                                        value-format="yyyy-MM"
                                        :clearable="false"
                                        :picker-options="{disabledDate:data=>startDatePicker(data,educationForm.schoolEndTime)}">
                        </el-date-picker>
                        <span class="" style="margin: 0 5px 0px 5px"> -</span>
                        <el-date-picker
                                :class="educationForm.studyTime=='至今'&&!educationForm.schoolEndTime?'time-picker':''"
                                v-model="educationForm.schoolEndTime" prefix-icon='el-icon-date' type="month"
                                :placeholder="educationForm.studyTime=='至今'&&!educationForm.schoolEndTime?'':'请选择'"
                                @change="changeTime('schoolEndTime','studyTime','educationForm')"
                                :clearable="false"
                                value-format="yyyy-MM" :picker-options="{
                    disabledDate: data =>endDatePicker(data, educationForm.schoolStartTime),
                    shortcuts: [
                       {
                            text: '至今',
                            onClick(picker) {
                            educationForm.studyTime='至今'
                                 picker.$emit('pick', '');
                                 educationForm.schoolEndTime=''
                            }
                        }]
                }">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="  "></el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="在校经历(选填)" prop="schoolException">
                        <el-input
                                type="textarea"
                                v-model="educationForm.schoolException"
                                placeholder="多行输入"
                        ></el-input>
                    </el-form-item>
                </el-row>
                <el-row class="last lastBtn">
                    <el-form-item class="btnList">
                        <el-button class="no" v-if="showEdit.box == 6" @click="$emit('update:showEdit', editObject)"
                        >取消
                        </el-button
                        >
                        <el-button class="ok" v-if="showEdit.box == 6" @click="submitForm('educationForm')"
                        >确认
                        </el-button
                        >
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
    </div>
</template>
<script>
    import Title1 from "@/components/Title1.vue";
    import {
        getBaseInfo,
        educationDrop,
        jobListPage,
        baseInfoAdd,
        jobExpectationAdd,
        getJobExpectation,
        getCity,
        workExperienceAdd,
        workExperienceDetail,
        projectExperienceAdd,
        projectExperienceDetail,
        educationalAdd,
        educationalDetail,
    } from "@/api/myResume";

    export default {
        name: "editResume",
        props: {
            showEdit: Object,
        },
        components: {
            Title1,
        },
        inject: ["reload"],
        data() {
            var judgeNull = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("必填项！"));
                }
                return callback();
            };
            var judgeNull2 = (rule, value, callback) => {
                if (!this.workForm.jobTime && !this.workForm.jobEndTime) {
                    return callback(new Error("必填项！"));
                }
                return callback();
            };
            var judgeNull3 = (rule, value, callback) => {
                if (!this.projectForm.projectTime && !this.projectForm.endTime) {
                    return callback(new Error("必填项！"));
                }
                return callback();
            };
            var judgeNull4 = (rule, value, callback) => {
                if (!this.educationForm.studyTime && !this.educationForm.schoolEndTime) {
                    return callback(new Error("必填项！"));
                }
                return callback();
            };
            return {
                test_options: [],
                personalForm: {
                    headImgUrl: null,
                    name: null,
                    gender: null,
                    birthday: null,
                    education: null,
                    phone: null,
                    jobExperience: null,
                    email: null,
                },
                educationList: [],
                advantageForm: {
                    personalAdvantage: null,
                },
                desireForm: {
                    jobType: null,
                    expectedPositionCode: null,
                    // expectedPositionCode: null,
                    // expectedPositionType: null,
                    expectedPositionType: null,
                    expectedSalaryMax: null,
                },
                postList: [],
                industryDictionary: [],
                postDictionary: [],
                industryList: [],
                educationSystemList: [],
                salaryList: [],
                workForm: {
                    corporateName: null,
                    jobTime: null,
                    positionCode: null,
                    companyIndustry: null,
                    companyIndustryName: null,
                    jobContent: null,
                    jobStartTime: null,
                    jobEndTime: null,
                },
                projectForm: {
                    projectName: null,
                    projectTime: null,
                    startTime: null,
                    endTime: null,
                    playRole: null,
                    projectContent: null,
                    jobDuty: null,
                },
                educationForm: {
                    schoolName: null,
                    educationSystemType: null,
                    education: null,
                    professionalName: null,
                    studyTime: null,
                    schoolStartTime:null,
                    schoolEndTime:null,
                    schoolException: null,
                },
                educationSystemTypeList: [
                    {
                        label: "全日制",
                        value: 1,
                    },
                    {
                        label: "全日制2",
                        value: 2,
                    },
                ],
                editObject: {
                    judge: "false",
                    box: 1,
                },
                personalRules: {
                    headImgUrl: [
                        {validator: judgeNull, message: "请上传头像！", trigger: "blur"},
                    ],
                    name: [
                        {validator: judgeNull, message: "请填写姓名！", trigger: "blur"},
                    ],
                    gender: [
                        {validator: judgeNull, message: "请选择性别！", trigger: "blur"},
                    ],
                    birthday: [
                        {
                            validator: judgeNull,
                            message: "请选择出生日期！",
                            trigger: "blur",
                        },
                    ],
                    educationMax: [
                        {
                            validator: judgeNull,
                            message: "请选择最高学历！",
                            trigger: "blur",
                        },
                    ],
                    phone: [
                        {
                            validator: judgeNull,
                            message: "请输入手机号码！",
                            trigger: "blur",
                        },
                    ],
                    jobExperience: [
                        {
                            validator: judgeNull,
                            message: "请输入工作年限！",
                            trigger: "blur",
                        },
                    ],
                    email: [
                        {
                            validator: judgeNull,
                            message: "请输入联系邮箱！",
                            trigger: "blur",
                        },
                    ],
                },
                advantageRules: {
                    personalAdvantage: [
                        {
                            validator: judgeNull,
                            message: "请输入个人优势！",
                            trigger: "blur",
                        },
                    ],
                },
                desireRules: {
                    jobType: [
                        {
                            validator: judgeNull,
                            message: "请选择职业类型！",
                            trigger: "blur",
                        },
                    ],
                    city: [
                        {
                            validator: judgeNull,
                            message: "请选择期望城市！",
                            trigger: "blur",
                        },
                    ],
                    expectedPositionCode: [
                        {
                            validator: judgeNull,
                            message: "请选择期望岗位！",
                            trigger: "blur",
                        },
                    ],
                    expectedPositionType: [
                        {
                            validator: judgeNull,
                            message: "请选择职业行业！",
                            trigger: "blur",
                        },
                    ],
                    expectedSalaryMax: [
                        {
                            validator: judgeNull,
                            message: "请选择期望薪资！",
                            trigger: "blur",
                        },
                    ],
                },
                workRules: {
                    corporateName: [
                        {
                            validator: judgeNull,
                            message: "请输入公司名称！",
                            trigger: "blur",
                        },
                    ],
                    jobStartTime: [
                        {
                            validator: judgeNull,
                            message: "请选择在职时间！",
                            trigger: "blur",
                        },
                        {
                            validator: judgeNull2,
                            message: "请选择结束在职时间！",
                            trigger: "blur",
                        },
                    ],
                    positionCode: [
                        {
                            validator: judgeNull,
                            message: "请输入担任岗位！",
                            trigger: "blur",
                        },
                    ],
                    companyIndustry: [
                        {
                            validator: judgeNull,
                            message: "请选择所属行业！",
                            trigger: "blur",
                        },
                    ],
                    jobContent: [
                        {
                            validator: judgeNull,
                            message: "请输入工作内容！",
                            trigger: "blur",
                        },
                    ],
                },
                projectRules: {
                    projectName: [
                        {
                            validator: judgeNull,
                            message: "请输入项目名称！",
                            trigger: "blur",
                        },
                    ],
                    startTime: [
                        {
                            validator: judgeNull,
                            message: "请选择项目时间！",
                            trigger: "blur",
                        },
                        {
                            validator: judgeNull3,
                            message: "请选择结束项目时间！",
                            trigger: "blur",
                        },
                    ],
                    playRole: [
                        {
                            validator: judgeNull,
                            message: "请输入担任角色！",
                            trigger: "blur",
                        },
                    ],
                    projectContent: [
                        {
                            validator: judgeNull,
                            message: "请输入项目介绍！",
                            trigger: "blur",
                        },
                    ],
                    jobDuty: [
                        {
                            validator: judgeNull,
                            message: "请输入工作职责！",
                            trigger: "blur",
                        },
                    ],
                },
                educationRules: {
                    schoolName: [
                        {
                            validator: judgeNull,
                            message: "请输入学校名称！",
                            trigger: "blur",
                        },
                    ],
                    educationSystemType: [
                        {
                            validator: judgeNull,
                            message: "请选择学制类型！",
                            trigger: "blur",
                        },
                    ],
                    education: [
                        {
                            validator: judgeNull,
                            message: "请选择学历！",
                            trigger: "blur",
                        },
                    ],
                    professionalName: [
                        {
                            validator: judgeNull,
                            message: "请输入专业！",
                            trigger: "blur",
                        },
                    ],
                    schoolStartTime: [
                        {
                            validator: judgeNull,
                            message: "请选择学习时间！",
                            trigger: "blur",
                        },
                        {
                            validator: judgeNull4,
                            message: "请选择结束学习时间！",
                            trigger: "blur",
                        },
                    ],
                },
                props: {
                    lazy: true,
                    lazyLoad(node, resolve) {
                        const {level} = node;
                        if (level == 0) {
                            getCity({level: level + 1}).then((list1) => {
                                let arr = list1.data.map((e) => ({
                                    value: e.dictCode,
                                    label: e.dictValue,
                                }));
                                resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                            });
                        } else if (level == 1) {
                            let {value} = node;
                            getCity({code: value, level: level + 1}).then((list2) => {
                                let arr = list2.data.map((e) => ({
                                    value: e.dictCode,
                                    label: e.dictValue,
                                }));
                                resolve(arr);
                            });
                        } else if (level == 2) {
                            let {value} = node;
                            getCity({code: value, level: level + 1}).then((list3) => {
                                let arr = list3.data.map((e) => ({
                                    value: e.dictCode,
                                    label: e.dictValue,
                                    leaf: true,
                                }));
                                resolve(arr);
                            });
                        }
                    },
                },
            };
        },
        mounted() {
            this.getEditInfo();
            this.getJobListPage(0);
        },
        methods: {
            expandChange(e) {
                console.log(e)
            },
            handleAvatarSuccess(res) {
                // this.personalForm.headImgUrl = URL.createObjectURL(file.raw);
                this.personalForm.headImgUrl = res.data.url;
            },
            beforeAvatarUpload(file) {
                // const isJPG = file.type === "image/jpeg";
                const isLt2M = file.size / 1024 / 1024 < 2;

                // if (!isJPG) {
                //     this.$message.error("上传头像图片只能是 JPG 格式!");
                // }
                if (!isLt2M) {
                    this.$message.error("上传头像图片大小不能超过 2MB!");
                }
                return isLt2M;
            },
            changeAge() {
                this.personalForm.age = (new Date().getFullYear()) - (new Date(this.personalForm.birthday).getFullYear())
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.showEdit.box == 1 || this.showEdit.box == 2) {
                            try {
                                this.personalForm.personalAdvantage =
                                    this.advantageForm.personalAdvantage;
                                (this.personalForm.educationMaxNme = this.educationList.filter(
                                    (item) => item.dictCode == this.personalForm.educationMax
                                )[0].dictLabel)
                            } catch (error) {
                                console.log(error)
                            }
                            baseInfoAdd(this.personalForm).then((res) => {
                                this.$message.success(res.msg);
                                this.$emit("update:showEdit", this.editObject);
                                this.reload();
                            });
                        } else if (this.showEdit.box == 3) {
                            let labelData =
                                this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels;
                            this.desireForm.areaCode = this.desireForm.city[2];
                            this.desireForm.cityCode = this.desireForm.city[1];
                            this.desireForm.provinCode = this.desireForm.city[0];
                            this.desireForm.areaName = labelData[2];
                            this.desireForm.cityName = labelData[1];
                            this.desireForm.provinName = labelData[0];

                            (this.desireForm.expectedSalaryMin = this.salaryList.filter(
                                (item) => item.dictCode == this.desireForm.expectedSalaryMax
                            )[0].dictLabel),
                                (this.desireForm.jobTypeName = this.industryList.filter(
                                    (item) => item.dictCode == this.desireForm.jobType
                                )[0].dictLabel)
                            jobExpectationAdd(this.desireForm).then((res) => {
                                this.$message.success(res.msg);
                                this.$emit("update:showEdit", this.editObject);
                            });
                        } else if (this.showEdit.box == 4) {
                            this.workForm.jobEndTime = this.workForm.jobTime ? this.workForm.jobTime : this.workForm.jobEndTime;
                            workExperienceAdd(this.workForm).then((res) => {
                                this.$message.success(res.msg);
                                this.$emit("update:showEdit", this.editObject);
                            });
                        } else if (this.showEdit.box == 5) {
                            this.projectForm.endTime = this.projectForm.projectTime?this.projectForm.projectTime:this.projectForm.endTime;
                            projectExperienceAdd(this.projectForm).then((res) => {
                                this.$message.success(res.msg);
                                this.$emit("update:showEdit", this.editObject);
                            });
                        } else if (this.showEdit.box == 6) {
                            this.educationForm.schoolEndTime = this.educationForm.studyTime?this.educationForm.studyTime:this.educationForm.schoolEndTime;
                            this.educationForm.educationName = this.educationList.filter(
                                (item) => item.dictCode == this.educationForm.education
                            )[0].dictLabel;
                            this.educationForm.educationSystemTypeName = this.educationSystemList.filter(
                                (item) => item.dictCode == this.educationForm.educationSystemType
                            )[0].dictLabel;
                            educationalAdd(this.educationForm).then((res) => {
                                this.$message.success(res.msg);
                                this.$emit("update:showEdit", this.editObject);
                            });
                        }
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            // 获取信息
            getEditInfo() {
                this.getDropList("educational", "educationList");
                this.getDropList("monthly", "salaryList");
                this.getDropList("position", "postList");
                this.getDropList("position_type ", "industryList");
                this.getDropList("education_system_type ", "educationSystemList");

                document.documentElement.scrollTop = document.getElementById('anchor-' + this.showEdit.box).offsetTop - 120;
                // 获取个人信息
                getBaseInfo({}).then((res) => {
                    if (res.data) {
                        this.personalForm = res.data;
                        this.personalForm.birthday = new Date(res.data.birthday)
                        this.personalForm.gender = this.personalForm.gender.toString();
                        this.advantageForm.personalAdvantage = res.data.personalAdvantage;
                    }
                });
                if (this.showEdit.type == 1) {
                    // 获取求职信息
                    getJobExpectation({}).then((res) => {
                        if (res.data) {
                            this.desireForm = res.data;
                            this.desireForm.city = [
                                res.data.provinCode,
                                res.data.cityCode,
                                res.data.areaCode,
                            ];
                            this.test_options = [{
                                value: res.data.provinCode,
                                label: res.data.provinName,
                                children: [{
                                    value: res.data.cityCode,
                                    label: res.data.cityName,
                                    children: [{
                                        value: res.data.areaCode,
                                        label: res.data.areaName,
                                        leaf: true,
                                    }]
                                }]
                            }]
                            let arr = [], arr1 = [], arr2 = []
                            getCity({level: 1}).then((list1) => {
                                arr = list1.data.map((e) => ({
                                    value: e.dictCode,
                                    label: e.dictValue,
                                }));
                                getCity({level: 2, code: res.data.provinCode}).then((list1) => {
                                    arr1 = list1.data.map((e) => ({
                                        value: e.dictCode,
                                        label: e.dictValue,
                                    }));
                                    arr.map(item => {
                                        if (item.value === res.data.provinCode) {
                                            item.children = arr1
                                        }
                                    })
                                    getCity({level: 3, code: res.data.cityCode}).then((list1) => {
                                        arr2 = list1.data.map((e) => ({
                                            value: e.dictCode,
                                            label: e.dictValue,
                                            leaf: true,
                                        }));
                                        arr1.map(item => {
                                            if (item.value === res.data.cityCode) {
                                                item.children = arr2
                                            }
                                        })
                                        this.test_options = arr
                                        console.log(this.test_options)
                                    });
                                });
                            });
                            this.desireForm.expectedSalaryMax =
                                this.desireForm.expectedSalaryMax.toString();
                            jobListPage({parentId: this.desireForm.expectedPositionType}).then((res) => {
                                this.postDictionary = res.data
                            })
                        }
                    });

                    // 获取工作经历
                    workExperienceDetail({}).then((res) => {
                        if (res.data) {
                            this.workForm = res.data[this.showEdit.index] || res.data[0] || this.workForm;
                            jobListPage({parentId: this.workForm.companyIndustry}).then((res) => {
                                this.postDictionary = res.data
                            })
                            if(this.workForm.jobEndTime=='至今'){
                                this.workForm.jobEndTime=null
                                this.workForm.jobTime='至今'
                            }
                        }
                    });
                    // 获取项目经历
                    projectExperienceDetail({}).then((res) => {
                        this.projectForm = res.rows[this.showEdit.index] || res.rows[0] || this.projectForm;
                        if(this.projectForm.endTime=='至今'){
                            this.projectForm.endTime=''
                            this.projectForm.projectTime='至今'
                        }
                    });

                    // 获取教育经历
                    educationalDetail({}).then((res) => {
                        if (res.data) {
                            this.educationForm = res.data[this.showEdit.index] || res.data[0] || this.educationForm;
                            if(this.educationForm.schoolEndTime=='至今'){
                                this.educationForm.schoolEndTime=''
                                this.educationForm.studyTime='至今'
                            }
                            this.educationForm.education =
                                this.educationForm.education.toString();
                        }
                    });
                }
            },
            // 获取下拉列表
            getDropList(val, arr) {
                educationDrop(val).then((res) => {
                    this[arr] = res.data;
                });
            },
            // 获取职业下拉字典
            getJobListPage(id, val) {
                if (id == 0) {
                    jobListPage({parentId: id}).then((res) => {
                        this.industryDictionary = res.data
                        console.log(res.data)
                    })
                } else {
                    if (val == 1) {
                        this.desireForm.expectedPositionCode = null
                    } else {
                        this.workForm.positionCode = null
                    }
                    jobListPage({parentId: id}).then((res) => {
                        this.postDictionary = res.data
                        console.log(res.data)
                    })
                }
            },
            selectChange2(v, arr, value, setArr) {
                arr.some(item => {
                    if (item.dictCode === v) {
                        this.$set(setArr, value, item.dictValue)
                        return true
                    }
                })
            },
            // 时间选择的先后限制
            startDatePicker(time, etime) {
                if (etime) {
                    let data = new Date(etime);
                    return time.getTime() > data.getTime();
                }
            },
            endDatePicker(time, btime) {
                if (btime) {
                    let data = new Date(btime);
                    return time.getTime() < data.getTime();
                }
            },
            changeTime(val1, val2,val3) {
                console.log(11,this[val3][val1])
                if (isNaN(this[val3][val1]) && !isNaN(Date.parse(this[val3][val1]))) {
                    this[val3][val2] = null
                } else {
                    this[val3][val2] = '至今'
                }
                console.log(12,this[val3][val2])
            }
        },
    };
</script>
<style lang="scss" scoped>
    @mixin formStyle {
        .el-form {
            margin-top: 24px;
        }
        /deep/ .el-form-item {
            margin-bottom: 20px;
            width: 276px;

            .el-form-item__label {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                margin-bottom: 8px;
            }

            .el-date-editor {
                width: 276px;
            }

            .el-input__inner {
                width: 276px;
                height: 38px;
                border-radius: 0;
                border: 1px solid #cccccc;
            }

            .el-button {
                width: 90px;
                height: 34px;
                border-radius: 0;
                line-height: 34px;
                padding: 0;
                font-size: 14px;
                font-weight: 400;
                margin-left: 0;
            }

            .el-textarea__inner {
                width: 868px;
                height: 200px;
                border-radius: 0px 0px 0px 0px;
                border: 1px solid #cccccc;
            }

            .no {
                color: #666666;
                border: 1px solid #cccccc;
                margin-right: 20px;
            }

            .ok {
                background-color: #0780e6;
                color: #fff;
            }
        }
        .btnList {
            /deep/ .el-form-item__content {
                text-align: end;
            }
        }
        .middle {
            margin: 0 20px;
        }
        /deep/ .radioStyle {
            .el-form-item__content {
                display: flex;
                line-height: 0;

                .el-radio {
                    width: 133px;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    border-radius: 0;
                    border: 1px solid #cccccc;
                    padding: 0;
                    margin: 0;

                    &:nth-child(1) {
                        margin-right: 10px;
                    }
                }

                .is-checked {
                    border: 1px solid #3580df;
                    color: #0780e6;
                    background-color: #edf5ff;
                }

                .el-radio__label {
                    padding: 0;
                }

                .el-radio__input {
                    display: none;
                }

                .el-radio.is-bordered.is-disabled {
                    background-color: #f5f7fa;
                }
            }
        }
        .last {
            /deep/ .el-form-item {
                margin-bottom: 0;
            }
        }
        .lastBtn {
            display: flex;
            justify-content: flex-end;
        }
    }

    @mixin sectionTime {
        .el-form-item__content {
            display: flex;
            justify-content: space-between;

            .el-date-editor {
                width: 48%;
            }

            .el-input__inner {
                width: 100%;
            }
        }
    }

    @mixin goodsManage {
        .time-picker {
            /deep/ .el-icon-date {
                &::before {
                    content: '至今';
                    display: block;
                    width: 30px;
                    color: #606266;
                }

                &::after {
                    content: '';
                }
            }
        }
    }

    .editResume {
        .package {
            width: 900px;
            background: #ffffff;
            padding: 24px 16px;
            margin-bottom: 20px;
        }

        .personal {
            @include formStyle();

            /deep/ .uploadAvatar {
                height: 78px;
                display: flex;
                align-items: center;

                .el-form-item__content {
                    line-height: 0;

                    .avatar {
                        width: 78px;
                        height: 78px;
                        border-radius: 50%;
                    }
                }

                .el-form-item__label {
                    padding: 0;
                    margin-right: 16px;
                }
            }
        }

        .advantage {
            @include formStyle();
        }

        .desire {
            @include formStyle();

            .salary {
                /deep/ .el-form-item::before {
                    display: none;
                }

                /deep/ .el-form-item__content {
                    display: flex;
                    justify-content: space-between;

                    .el-input__inner {
                        width: 124px;
                    }
                }
            }
        }

        .work {
            @include formStyle();

            /deep/ .sectionTime {
                @include sectionTime()
            }

            .goodsManage {
                @include goodsManage()
            }
        }

        .project {
            @include formStyle();

            /deep/ .sectionTime {
                @include sectionTime()
            }

            .goodsManage {
                @include goodsManage()
            }
        }

        .education {
            @include formStyle();
            width: 900px;
            background: #ffffff;
            padding: 24px 16px;
            /deep/ .sectionTime {
                @include sectionTime()
            }
            .goodsManage {
                @include goodsManage()
            }
        }
    }
</style>
