<template>
  <div class="seeResume">
    <div class="advantage package" id="anchor-2">
      <Title1 :titles="'个人优势'" wh="724"></Title1>
      <div class="details">
        <ul v-if="personalForm.personalAdvantage.length > 0">
          <li
            v-for="(item, index) in personalForm.personalAdvantage"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
        <ul v-else>
          <li>请先填写</li>
        </ul>
        <div class="editBox">
          <p
            class="edit"
            @click="editFn(2, 1)"
            v-if="personalForm.personalAdvantage.length > 0"
          >
            <img src="@/assets/img/Index_JobSeeker/icon26.png" alt="" />编辑
          </p>
          <p class="edit" @click="editFn(2, 2)" v-else>
            <img src="@/assets/img/Index_JobSeeker/icon27.png" alt="" />添加
          </p>
        </div>
      </div>
    </div>
    <div class="desire package" id="anchor-3">
      <Title1 :titles="'求职愿望'" wh="724"></Title1>
      <div class="details">
        <p class="desireLabel">
          <span v-for="(item, index) in desireList" :key="index">
            {{ item }}
          </span>
        </p>
        <div class="editBox">
          <p
            class="edit"
            @click="editFn(3, 1)"
            v-if="desireList[0] != '请先填写'"
          >
            <img src="@/assets/img/Index_JobSeeker/icon26.png" alt="" />编辑
          </p>
          <p class="edit" @click="editFn(3, 2)" v-else>
            <img src="@/assets/img/Index_JobSeeker/icon27.png" alt="" />添加
          </p>
        </div>
      </div>
    </div>
    <div class="work package" id="anchor-4">
      <Title1 :titles="'工作经历'" wh="724"></Title1>
      <template v-if="advList.length > 0">
        <div class="details" v-for="(item, index) in advList" :key="index">
          <div class="company">
            <div class="top">
              <!-- <div class="companyImg">
              <img src="@/assets/img/Index_JobSeeker/company.jpg" alt="" />
            </div> -->
              <div class="title">
                <p>{{ item.corporateName }}</p>
                <p>
                  <span>{{ item.companyIndustryName }}</span
                  ><span>{{ item.positionName }}</span>
                </p>
                <p class="timer">
                  {{ item.jobStartTime + ' - ' + item.jobEndTime }}
                </p>
              </div>
            </div>
            <div class="bottom">
              <ul>
                <li v-html="item.jobContent && item.jobContent.replace(/\n/g, '<br />')"></li>
                <!-- <li v-for="(item, index) in advList" :key="index">
                {{ index + 1 + "、" + item }}
              </li> -->
              </ul>
            </div>
          </div>
          <div class="editBox">
            <p class="edit" @click="editFn(4, 1, index)">
              <img src="@/assets/img/Index_JobSeeker/icon26.png" alt="" />编辑
            </p>
            <p class="edit" @click="editFn(4, 2)">
              <img src="@/assets/img/Index_JobSeeker/icon27.png" alt="" />添加
            </p>
            <p class="edit" @click="workExperienceDelFn(item.id)">
              <img src="@/assets/img/Index_JobSeeker/icon28.png" alt="" />删除
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="details">
          <div>请先填写</div>
          <div class="editBox">
            <p class="edit" @click="editFn(4, 2)">
              <img src="@/assets/img/Index_JobSeeker/icon27.png" alt="" />添加
            </p>
          </div>
        </div>
      </template>
    </div>
    <div class="project package" id="anchor-5">
      <Title1 :titles="'项目经历'" wh="724"></Title1>
      <template v-if="projectList.length > 0">
        <div class="details" v-for="(item, index) in projectList" :key="index">
          <div class="top">
            <p>{{ item.projectName }}</p>
            <p class="timer">
              {{ item.startTime + ' - ' + item.endTime }}
            </p>
            <div>
              <div v-html="item.projectContent && item.projectContent.replace(/\n/g, '<br />')"></div>
            </div>
          </div>
          <div class="bottom">
            <p>工作职责：</p>
            <ul>
              <li v-html="item.jobDuty && item.jobDuty.replace(/\n/g, '<br />')"></li>
            </ul>
          </div>
          <div class="editBox">
            <p class="edit" @click="editFn(5, 1, index)">
              <img src="@/assets/img/Index_JobSeeker/icon26.png" alt="" />编辑
            </p>
            <p class="edit" @click="editFn(5, 2)">
              <img src="@/assets/img/Index_JobSeeker/icon27.png" alt="" />添加
            </p>
            <p class="edit" @click="projectExperienceDelFn(item.id)">
              <img src="@/assets/img/Index_JobSeeker/icon28.png" alt="" />删除
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="details">
          <div>请先填写</div>
          <div class="editBox">
            <p class="edit" @click="editFn(5, 2)">
              <img src="@/assets/img/Index_JobSeeker/icon27.png" alt="" />添加
            </p>
          </div>
        </div>
      </template>
    </div>
    <div class="education project" id="anchor-6">
      <Title1 :titles="'教育经历'" wh="724"></Title1>
      <template v-if="educationalList.length > 0">
        <div
          class="details"
          v-for="(item, index) in educationalList"
          :key="index"
        >
          <div class="company">
            <div class="top">
              <div class="title">
                <p>{{ item.schoolName }}</p>
                <p>
                  <span>{{ item.educationName+'.'+item.educationSystemTypeName }}</span
                  ><span>{{ item.professionalName }}</span>
                </p>
                <p class="timer">
                  {{ item.schoolStartTime + ' - ' + item.schoolEndTime }}
                </p>
              </div>
            </div>
            <div class="bottom">
              <p>在校经历：</p>
              <ul>
                <li  v-html="item.schoolException && item.schoolException.replace(/\n/g, '<br />')"></li>
              </ul>
            </div>
          </div>
          <div class="editBox">
            <p class="edit" @click="editFn(6, 1, index)">
              <img src="@/assets/img/Index_JobSeeker/icon26.png" alt="" />编辑
            </p>
            <p class="edit" @click="editFn(6, 2)">
              <img src="@/assets/img/Index_JobSeeker/icon27.png" alt="" />添加
            </p>
            <p class="edit" @click="educationalDelFn(item.id)">
              <img src="@/assets/img/Index_JobSeeker/icon28.png" alt="" />删除
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="details">
          <div>请先填写</div>
          <div class="editBox">
            <p class="edit" @click="editFn(6, 2)">
              <img src="@/assets/img/Index_JobSeeker/icon27.png" alt="" />添加
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Title1 from '@/components/Title1.vue';
import {
  getBaseInfo,
  getJobExpectation,
  workExperienceDetail,
  workExperienceDel,
  projectExperienceDetail,
  projectExperienceDel,
  educationalDetail,
  educationalDel,
} from '@/api/myResume';

export default {
  name: 'seeResume',
  props: {
    showEdit: Object,
  },
  components: {
    Title1,
  },
  data() {
    return {
      advList: [],
      projectList: [],
      desireList: [],
      editObject: {
        judge: 'true',
        box: 2,
        type: 1, //1：编辑 2：添加
      },
      personalForm: { personalAdvantage: [] }, //基本信息及个人优势
      educationalList: [],
    };
  },
  mounted() {
    this.getAllInfo();
  },
  methods: {
    editFn(val, type, index) {
      this.$emit('update:showEdit', {
        ...this.editObject,
        box: val,
        type,
        index: index || 0,
      });
    },
    // 获取基本信息
    getAllInfo() {
      getBaseInfo({}).then((res) => {
        console.log(res.data);
        if (res.data) {
          res.data.personalAdvantage = res.data.personalAdvantage.split('\n');
          this.personalForm = res.data;
        }
      });
      getJobExpectation({}).then((res) => {
        if (res.data) {
          this.desireList = [
            res.data.jobTypeName,
            res.data.cityName,
            res.data.expectedPositionTypeName,
            res.data.expectedPositionName,
            res.data.expectedSalaryMin,
          ];
        } else {
          this.desireList = ['请先填写'];
        }
      });
      workExperienceDetail({}).then((res) => {
        if (res.data) {
          this.advList = res.data;
        }
      });
      projectExperienceDetail({}).then((res) => {
        this.projectList = res.rows;
      });
      educationalDetail({}).then((res) => {
        this.educationalList = res.data;
      });
    },

    workExperienceDelFn(id) {
      workExperienceDel(id).then((res) => {
        this.$message.success(res.msg);
        workExperienceDetail({}).then((res) => {
          this.advList = res.data;
        });
      });
    },
    projectExperienceDelFn(id) {
      projectExperienceDel(id).then((res) => {
        this.$message.success(res.msg);
        projectExperienceDetail({}).then((res) => {
          this.projectList = res.rows;
        });
      });
    },
    educationalDelFn(id) {
      educationalDel(id).then((res) => {
        this.$message.success(res.msg);
        educationalDetail({}).then((res) => {
          this.educationalList = res.data;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin category {
  span {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    padding: 0 16px;
    border-right: 1px solid #666666;
    &:nth-child(1) {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0 !important;
      border-right: none !important;
    }
  }
}
@mixin introduce {
  display: flex;
  margin-bottom: 24px;
  .companyImg {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .title {
    p {
      &:nth-child(1) {
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
        margin-bottom: 8px;
      }
      &:nth-child(2) {
        @include category();
      }
    }
  }
}
.seeResume {
  .details {
    margin-top: 24px;
    position: relative;
    padding-right: 60px;
  }
  .details:hover .edit {
    display: block;
  }
  .editBox {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    .edit {
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #0780e6;
      line-height: 22px;
      margin-right: 16px;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
        vertical-align: text-bottom;
        position: relative;
        left: -5px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .package {
    width: 900px;
    background: #ffffff;
    padding: 24px 16px;
    margin-bottom: 20px;
  }
  .advantage {
    li {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
    }
  }
  .desire {
    .desireLabel {
      @include category();
    }
  }
  .work {
    .company {
      margin-top: 24px;
      .top {
        @include introduce();
      }
      .bottom {
        li {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
        }
      }
    }
  }
  .project {
    .top {
      margin-bottom: 24px;
      p {
        &:nth-child(1) {
          height: 22px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 22px;
          margin-bottom: 16px;
        }
        &:nth-child(2) {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
      }
    }
    .bottom {
      p {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-bottom: 16px;
      }
      li {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
      }
    }
  }
  .education {
    width: 900px;
    background: #ffffff;
    padding: 24px 16px;
    .details {
      @include introduce();
      margin-bottom: 0;
    }
  }
  .timer {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin: 8px 0;
  }
}
</style>
