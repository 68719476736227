<template>
  <div class="myResume">
    <Head Offset="Offset"></Head>
    <div class="main">
      <Introduce
        judge="false"
        v-if="showEdit.judge == 'false'"
        :showEdit.sync="showEdit"
      ></Introduce>
      <div class="content fs">
        <div class="left">
          <div :class="sideFixed == true ? 'sideBarWrap' : ''">
            <div class="top">
              <p class="title">附件简历</p>
              <ul class="listFile">
                <li v-for="(item,idx) in fileList" :key="item.id">
                  <img width="16" style="margin-right:5px" height="17" src="@/assets/img/Index_JobSeeker/list.png" alt="" />
                  <div class="name">{{ item.name }}</div>
                  <img @click="resumeTopFn(item.id, 2)" width="22" height="22" src="@/assets/img/Index_JobSeeker/icon24_1.png" alt="" />
                  <img @click.stop="del(idx, item.name)" class="del" width="17" height="17" src="@/assets/img/Index_JobSeeker/icon20.png" alt="" />
                </li>
              </ul>
              <el-upload
                class="upload-demo"
                action="http://danlingzp.com/prod-api/system/oss/upload"
                :before-upload="beforeUpload"
                :on-success="resumeAddFn"
                :show-file-list="false"
                :file-list="fileList"
              >
                <el-button icon="el-icon-plus">上传附件简历</el-button>
              </el-upload>
            </div>
            <ul class="bottom">
              <li v-anchor="1">个人信息</li>
              <li v-anchor="2">个人优势</li>
              <li v-anchor="3">求职愿望</li>
              <li v-anchor="4">工作经历</li>
              <li v-anchor="5">项目经历</li>
              <li v-anchor="6">教育经历</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="top fs">
            <div class="leftBox">
              <p>简历完善度：</p>
              <el-progress
                :percentage="baseInfo.basicCompletion || 0"
              ></el-progress>
            </div>
            <div class="rightBox" v-show="baseInfo.name">
              <p @click="resumeTopFn()">
                <img
                  src="@/assets/img/Index_JobSeeker/icon24.png"
                  alt=""
                /><span>置顶简历</span>
              </p>
              <p>
                <img
                  src="@/assets/img/Index_JobSeeker/icon17.png"
                  alt=""
                /><span>更新时间：{{ baseInfo.updateTime }}</span>
              </p>
            </div>
          </div>
          <div class="bottom">
            <seeResume
              v-if="showEdit.judge == 'false'"
              :showEdit.sync="showEdit"
            ></seeResume>
            <editResume
              v-if="showEdit.judge == 'true'"
              :showEdit.sync="showEdit"
            ></editResume>
          </div>
        </div>
      </div>
      <el-backtop :bottom="100" :visibility-height="50"></el-backtop>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Introduce from '@/components/Introduce.vue';
import seeResume from '@/views/MyResume/seeResume.vue';
import editResume from '@/views/MyResume/editResume.vue';
import {
  resumeAdd,
  resumeList,
  resumeRemove,
  getBaseInfo,
  resumeTop,
} from '@/api/myResume';
export default {
  name: 'myResume',
  components: {
    Head,
    Footer,
    Introduce,
    seeResume,
    editResume,
  },
  data() {
    return {
      fileList: [],
      sideFixed: false,
      percentage: 50,
      baseInfo: {},
      showEdit: {
        judge: 'false',
        box: 1,
      },
    };
  },
  mounted() {
    this.getResumeList();
    this.getBaseInfoFn();
  },
  methods: {
    beforeUpload(file){
      let ext = file.name.split('.').pop()
      if(['doc','docx', 'xls', 'xlsx', 'pdf'].indexOf(ext.toLocaleLowerCase()) == -1) {
        this.$message.warning('简历只能支持doc、docx、xls、xlsx、pdf格式上传')
        return false
      }
    },
    // 移除简历
    handleRemove(file) {
      resumeRemove(file.id).then((res) => {
        this.$message.success(res.msg);
      });
    },
    del(idx, name){
      this.$confirm(`确定移除 ${name}？`).then(()=> {
        resumeRemove(this.fileList[idx].id).then((res) => {
          this.$message.success(res.msg);
          this.getResumeList();
        });
      });
    },
    // 移除简历前询问
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 新增简历
    resumeAddFn(response, file) {
      var obj = {
        resumeName: response.data.fileName,
        resumeUrl: response.data.url,
        resource: 2,
        fileSize: file.size
      };
      resumeAdd(obj).then((res) => {
        this.$message.success(res.msg);
        this.getResumeList();
      });
    },

    // 读取简历信息
    getResumeList() {
      resumeList({}).then((res) => {
        this.fileList = [];
        res.data.forEach((item) => {
          this.fileList.push({
            name: item.resumeName,
            resumeUrl: item.resumeUrl,
            id: item.id,
          });
        });
      });
    },
    // 获取简历基本信息
    getBaseInfoFn() {
      getBaseInfo({}).then((res) => {
        if (res.data) {
          this.baseInfo = res.data;
        }
      });
    },
    // 置顶简历
    resumeTopFn(id, resource) {
      resumeTop({ id: id?id:this.baseInfo.id, resource: resource?2:1 }).then((res) => {
        this.$message.success(res.msg);
        this.getResumeList();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.myResume {
  width: 100%;
  height: 100%;
  .main {
    min-height: calc(100% - 278px);
    // padding-top: 20px;
    margin-bottom: 93px;
  }
  .content {
    margin-top: 20px;
  }
  .listFile{
    overflow: hidden;
    padding-top: 10px;
    li{
      display: flex;
      padding: 14px 0;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      align-items: center;
      .name{
        flex: 1;
      }
      img{
        vertical-align: middle;
        width: 16px;
        height: 16px;
        &:hover{
          opacity: 0.8;
        }
      }
      .del{
        margin-left: 5px;
      }
      &:last-child{
        border: 0;
      }
    }
  }
  .left {
    width: 280px;
    position: relative;
    .top {
      background: #ffffff;
      padding: 24px 16px 84px 16px;
      margin-bottom: 20px;
      .title {
        height: 25px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
      }
      .el-button {
        width: 248px;
        height: 36px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #0780e6;
        color: #0780e6;
        font-size: 16px;
        padding: 0;
      }
      .upload-demo {
        position: relative;
      }
      /deep/ .el-upload {
        position: absolute;
        left: 0;
        bottom: -60px;
      }
    }
    .bottom {
      padding: 0 16px;
      background: #ffffff;
      li {
        height: 98px;
        line-height: 98px;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid #eeeeee;
        &:last-child {
          border-bottom: none;
        }
        a {
          color: #333333;
        }
      }
    }
  }
  .sideBarWrap {
    position: fixed;
    top: 100px;
    width: 280px;
  }
  .right {
    .top {
      width: 900px;
      height: 111px;
      background: #ffffff;
      padding: 24px 16px;
      margin-bottom: 20px;
    }
    .leftBox {
      p {
        height: 25px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
        margin-bottom: 21px;
      }
      /deep/ .el-progress {
        width: 388px;
        .el-progress-bar__outer {
          height: 12px !important;
          .el-progress-bar__inner {
            background: linear-gradient(135deg, #7cc3ff 0%, #3580df 100%);
          }
        }
        .el-progress__text {
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #0780e6;
          line-height: 22px;
        }
      }
    }
    .rightBox {
      img {
        vertical-align: middle;
        margin-right: 8px;
        width: 22px;
        height: 22px;
      }
      p {
        &:nth-child(1) {
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #0780e6;
          line-height: 22px;
          margin-bottom: 18px;
          cursor: pointer;
        }
        &:nth-child(2) {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          img{
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
/deep/.el-upload-list__item.is-success.focusing .el-icon-close-tip {
  display: none !important;
}
</style>
