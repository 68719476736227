<template>
  <div class="introduce">
    <div class="content fcs">
      <div class="left fcs">
        <div class="headPortrait" :style="'background-image:url('+baseInfo.headImgUrl || '../assets/img/Index_JobSeeker/head-portrait2.png'+')'">
        </div>
        <ul>
          <li>{{ baseInfo.name }}</li>
          <li>
            <p>
              <span>{{ baseInfo.age }}岁</span
              ><span>{{ baseInfo.jobExperience }}年</span
              ><span>{{ baseInfo.educationMaxNme }}</span>
            </p>
            <p>
              <span
                ><img src="@/assets/img/Index_JobSeeker/icon22.png" alt="" />{{
                  baseInfo.phone || "无"
                }}</span
              >
              <span
                ><img class="yx" src="@/assets/img/Index_JobSeeker/icon23.png" alt="" />{{
                  baseInfo.email || "无"
                }}</span
              >
            </p>
          </li>
        </ul>
      </div>
      <div class="right" v-if="judge != 'false'">
        <el-select
          v-model="state"
          placeholder="简历状态"
          :disabled="!baseInfo.name"
          @change="changeState()"
        >
          <el-option
            v-for="item in stateList"
            :key="item.code"
            :label="item.value"
            :value="item.code"
            class="drop"
          >
          </el-option>
        </el-select>
      </div>
      <div class="editBox" v-if="judge == 'false'">
        <p class="edit" @click="editFn(1, 1)" v-if="baseInfo.name">
          <img src="@/assets/img/Index_JobSeeker/icon26.png" alt="" />编辑
        </p>
        <p class="edit" @click="editFn(1, 2)" v-if="!baseInfo.name">
          <img src="@/assets/img/Index_JobSeeker/icon27.png" alt="" />添加
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import {
  resumeState,
  editStatus,
  getBaseInfo,
  currStatus,
} from "@/api/myResume";
export default {
  name: "introduce",
  props: ["judge"],
  data() {
    return {
      state: null, //简历状态
      stateList: [],
      baseInfo: {},
      editObject: {
        judge: "true",
        box: 1,
        type: 1,
        index: 0,
      },
    };
  },
  mounted() {
    this.getStateList();
    this.getBaseInfoFn();
    this.getCurrStatus();
  },
  methods: {
    // 获取简历列表
    getStateList() {
      resumeState({}).then((res) => {
        this.stateList = res.data;
      });
    },
    // 改变简历状态
    changeState() {
      editStatus({ status: this.state }).then((res) => {
        this.$message.success(res.msg);
      });
    },
    // 获取简历基本信息
    getBaseInfoFn() {
      getBaseInfo({}).then((res) => {
        if (res.data) {
          this.baseInfo = res.data;
        }
      });
    },
    // 查看当前简历状态
    getCurrStatus() {
      currStatus({}).then((res) => {
        if (res.data) {
          this.state = res.data.code;
        }
      });
    },

    editFn(val, type) {
      this.editObject.box = val;
      this.editObject.type = type;
      this.$emit("update:showEdit", this.editObject);
    },
  },
};
</script>
<style scoped>
.introduce {
  height: 182px;
  background: #edf5ff;
  margin-bottom: 20px;
}
.introduce .content {
  position: relative;
}

.introduce .left {
  height: 182px;
}
.introduce .left .headPortrait {
  height: 134px;
  width: 134px;
  margin-right: 20px;
  border-radius: 50%;
  background-size: 100% auto;
  background-position: center center;
}
.introduce .left .headPortrait img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.introduce .left li:nth-child(1) {
  height: 40px;
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  line-height: 40px;
  margin-bottom: 24px;
}
.introduce .left li:nth-child(2) {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  display: flex;
}
.introduce .left li:nth-child(2) img {
  vertical-align: middle;
  margin-right: 8px;
  width: 18px;
  height: 18px;
}
.introduce .left li:nth-child(2) img.yx {
  top: -1px;
}
.introduce .left li:nth-child(2) p:nth-child(1) span {
  padding: 0 16px;
  border-right: 1px solid #666;
}

.introduce .left li:nth-child(2) p:nth-child(1) span:nth-child(1) {
  padding-left: 0;
}
.introduce .left li:nth-child(2) p:nth-child(1) span:last-child {
  padding-right: 0;
  border-right: none;
}
.introduce .left li:nth-child(2) p:nth-child(2) {
  margin-left: 48px;
}

.introduce .left li:nth-child(2) p:nth-child(2) span:nth-child(1) {
  margin-right: 24px;
}
.introduce .right /deep/ .el-select .el-input__inner {
  width: 160px;
  height: 44px;
  border-radius: 24px;
  border: 1px solid #0780e6;
  background-color: transparent;
  text-align: center;
  color: #0780e6;
  font-size: 16px;
}

.introduce
  .right
  /deep/
  .el-select
  .el-input__inner::-webkit-input-placeholder {
  color: #0780e6;
}
.introduce .right /deep/ .el-select .el-select__caret {
  color: #0780e6;
}
.introduce .content:hover .editBox .edit {
  display: block;
}
.introduce .editBox {
  position: absolute;
  top: 44%;
  right: 0;
  display: flex;
}
.introduce .editBox .edit {
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #0780e6;
  line-height: 22px;
  margin-right: 16px;
  cursor: pointer;
}
.introduce .editBox .edit img {
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
  margin-right: 5px;
}
.introduce .right /deep/ .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.3;
}
.introduce .right /deep/ .el-select .is-disabled .el-select__caret{
  filter: grayscale(100%);
  opacity: 0.3;
}
</style>
